
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  loanAmount: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const submitButton1 = ref<HTMLElement | null>(null);
    const invProjCalculated = false;

    const yearlyPlanSchedule = {
      ofTerm: "",
      nominal: {
        accumulatedDepositsBF: 0,
        additionalContribution: 0,
        dividend: 0,
        accumulatedDepositsCF: 0,
      },
      withInflation: {
        accumulatedDepositsRealBF: 0,
        additionalContribution: 0,
        dividendWithInflation: 0,
        accumulatedDepositsRealCF: 0,
      },
    };

    const totalPeriods = [
      {
        value: 1,
        label: "Annually",
      },
      // {
      //   value: 4,
      //   label: "Quarterly",
      // },
      {
        value: 12,
        label: "Monthly",
      },
      {
        value: 52.14,
        label: "Weekly",
      },
      {
        value: 365,
        label: "Daily",
      },
    ];

    const plan = [];

    const totalAdditionalContributionN = 0;
    const totalAdditionalContribution = 0;
    const totalReturnOrInterestNominal = 0;
    const totalReturnOrInterestWithInflation = 0;
    const totalInvestmentNominal = 0;
    const totalInvestmentWithInflation = 0;
    const contibutionMode = "end";

    return {
      totalPeriods,
      id: route.params.id ?? null,
      store: useStore(),
      submitButton1,
      invProj: {
        calculator_type_id: 11,
        raw: {
          yourTarget: 100000,
          startingDeposit: 10000,
          annualDividendRate: 5,
          period: 1,
          duration: 0,
          durationI: 0,
          annualFlatInflationRate: 2,
          additionalContribution: 1000,
          adjustedRateOfReturn: 2.94,
        },
      },
      yearlyPlanSchedule,
      plan,
      invProjCalculated,
      totalAdditionalContributionN,
      totalAdditionalContribution,
      totalReturnOrInterestNominal,
      totalReturnOrInterestWithInflation,
      totalInvestmentNominal,
      totalInvestmentWithInflation,
      contibutionMode,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const investmentValidator = Yup.object({
      raw: Yup.object({
        startingDeposit: Yup.string().required().label("Existing EPF balance"),
        annualDividendRate: Yup.string().required().label("Annual Salary"),
        period: Yup.string().required().label("Current Annual EPF"),
        annualFlatInflationRate: Yup.number()
          .required()
          .label("Growth rate of salary "),
        additionalContribution: Yup.number()
          .required()
          .label("Rate of Dividend "),
        adjustedRateOfReturn: Yup.number()
          .required()
          .label("Rate of contribution to EPF "),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Investment Projection", [
        "Financial Calculators",
      ]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const handleClick = (tab: string, event: Event) => {
      // console.log(tab, event);
    };

    const lastYearPlan = {
      nominal: {
        ofTerm: "",
        accumulatedDepositsBF: 0,
        additionalContribution: 0,
        dividend: 0,
        accumulatedDepositsCF: 0,
      },
      withInflation: {
        ofTerm: "",
        accumulatedDepositsRealBF: 0,
        additionalContribution: 0,
        dividendWithInflation: 0,
        accumulatedDepositsRealCF: 0,
      },
    };

    return {
      investmentValidator,
      translate,
      nFormat,
      handleClick,
      lastYearPlan,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.invProj = this.store.getters.getCalculatorData.raw;
          this.calculate();
        });
      }
    },
    calculateRateOfReturn() {
      let anlDivRate = 1 + this.invProj.raw.annualDividendRate / 100;
      let anlFlatInflationRate =
        1 + this.invProj.raw.annualFlatInflationRate / 100;
      let adjustedRateOfReturn = (anlDivRate / anlFlatInflationRate - 1) * 100;
      this.invProj.raw.adjustedRateOfReturn = adjustedRateOfReturn;
    },
    setDurationLimit(e) {
      // console.log(this.invProj.raw.period);
    },
    fvFunction(n, P, r, PV, FV) {
      return (P * (Math.pow(1 + r, n) - 1)) / r + PV * Math.pow(1 + r, n) - FV;
    },
    fvDerivative(n, P, r, PV) {
      return (
        (P * Math.pow(1 + r, n) * Math.log(1 + r)) / r +
        PV * Math.pow(1 + r, n) * Math.log(1 + r)
      );
    },
    solveForN(
      P,
      r,
      PV,
      FV,
      initialGuess = 0.5,
      tolerance = 1e-6,
      maxIterations = 10000
    ) {
      let n = initialGuess;

      for (let i = 0; i < maxIterations; i++) {
        let fn = this.fvFunction(n, P, r, PV, FV);
        let fprime = this.fvDerivative(n, P, r, PV);

        if (Math.abs(fn) < tolerance) {
          return n;
        }

        n = n - fn / fprime;
      }

      throw new Error(
        "Did not converge to a solution within the maximum number of iterations."
      );
    },
    f(n, P, r, PV, FV) {
      return (
        P * ((Math.pow(1 + r, n) - 1) / r) * (1 + r) +
        PV * Math.pow(1 + r, n) -
        FV
      );
    },
    fPrime(n, P, r, PV) {
      const base = 1 + r;
      return (
        (P * Math.log(base) * Math.pow(base, n) * (1 + r)) / r +
        PV * Math.log(base) * Math.pow(base, n)
      );
    },
    findNUsingNewtonsMethod(
      P,
      r,
      PV,
      FV,
      initialGuess,
      tolerance = 1e-6,
      maxIterations = 1000
    ) {
      let n = initialGuess;
      for (let i = 0; i < maxIterations; i++) {
        const currentValue = this.f(n, P, r, PV, FV);
        if (Math.abs(currentValue) < tolerance) {
          return n;
        }
        n = n - currentValue / this.fPrime(n, P, r, PV);
      }
      throw new Error("Newton's method did not converge.");
    },
    calculate() {
      let P = this.invProj.raw.additionalContribution;
      let PV = this.invProj.raw.startingDeposit;
      let FV = this.invProj.raw.yourTarget;

      this.plan = [];
      let annualDividendRate = this.invProj.raw.annualDividendRate / 100;
      let DividendRate = annualDividendRate / this.invProj.raw.period;
      let r = DividendRate;
      let addjustedRate = this.invProj.raw.adjustedRateOfReturn / 100;
      let addjustedDividendRate = addjustedRate / this.invProj.raw.period;
      let ri = addjustedDividendRate;

      let accumulatedDepositsBF = this.invProj.raw.startingDeposit;
      let additionalContribution = this.invProj.raw.additionalContribution;
      let dividend = 0;
      if (this.contibutionMode == "end") {
        dividend = accumulatedDepositsBF * DividendRate;
      } else {
        dividend =
          (accumulatedDepositsBF + additionalContribution) * DividendRate;
      }

      let accumulatedDepositsCF =
        accumulatedDepositsBF + additionalContribution + dividend;
      let accumulatedDepositsRealBF = this.invProj.raw.startingDeposit;
      let additionalContributionI = this.invProj.raw.additionalContribution;

      let dividendWithInflation = 0;
      if (this.contibutionMode == "end") {
        dividendWithInflation =
          accumulatedDepositsRealBF * addjustedDividendRate;
      } else {
        dividendWithInflation =
          (accumulatedDepositsRealBF + additionalContributionI) *
          addjustedDividendRate;
      }

      let accumulatedDepositsRealCF =
        accumulatedDepositsRealBF +
        additionalContributionI +
        dividendWithInflation;

      let year = this.ordinal_suffix_of(1);

      let result;
      let resultI;

      if (this.contibutionMode == "end") {
        result = this.solveForN(P, r, PV, FV);
        resultI = this.solveForN(P, ri, PV, FV);
      } else {
        result = this.findNUsingNewtonsMethod(P, r, PV, FV, 1);
        resultI = this.findNUsingNewtonsMethod(P, ri, PV, FV, 1);
      }

      this.invProj.raw.duration = Math.floor(result);
      this.invProj.raw.durationI = Math.floor(resultI);

      this.yearlyPlanSchedule = {
        ofTerm: year,
        nominal: {
          accumulatedDepositsBF: accumulatedDepositsBF,
          additionalContribution: additionalContribution,
          dividend: dividend,
          accumulatedDepositsCF: accumulatedDepositsCF,
        },
        withInflation: {
          accumulatedDepositsRealBF: accumulatedDepositsRealBF,
          additionalContribution: additionalContributionI,
          dividendWithInflation: dividendWithInflation,
          accumulatedDepositsRealCF: accumulatedDepositsRealCF,
        },
      };
      this.totalAdditionalContributionN = additionalContribution;
      this.totalAdditionalContribution = additionalContribution;
      this.totalReturnOrInterestNominal = dividend;
      this.totalReturnOrInterestWithInflation = dividendWithInflation;

      this.plan.push(this.yearlyPlanSchedule);

      for (let i = 1; i < this.invProj.raw.durationI; i++) {
        let year = this.ordinal_suffix_of(i + 1);
        accumulatedDepositsBF = accumulatedDepositsCF;
        additionalContribution = this.invProj.raw.additionalContribution;
        if (this.contibutionMode == "end") {
          dividend = accumulatedDepositsBF * DividendRate;
        } else {
          dividend =
            (accumulatedDepositsBF + additionalContribution) * DividendRate;
        }
        accumulatedDepositsCF =
          accumulatedDepositsBF + additionalContribution + dividend;

        accumulatedDepositsRealBF = accumulatedDepositsRealCF;
        additionalContributionI = this.invProj.raw.additionalContribution;
        if (this.contibutionMode == "end") {
          dividendWithInflation =
            accumulatedDepositsRealBF * addjustedDividendRate;
        } else {
          dividendWithInflation =
            (accumulatedDepositsRealBF + additionalContributionI) *
            addjustedDividendRate;
        }
        accumulatedDepositsRealCF =
          accumulatedDepositsRealBF +
          additionalContributionI +
          dividendWithInflation;

        this.yearlyPlanSchedule = {
          ofTerm: year,
          nominal: {
            accumulatedDepositsBF: accumulatedDepositsBF,
            additionalContribution: additionalContribution,
            dividend: dividend,
            accumulatedDepositsCF: accumulatedDepositsCF,
          },
          withInflation: {
            accumulatedDepositsRealBF: accumulatedDepositsRealBF,
            additionalContribution: additionalContributionI,
            dividendWithInflation: dividendWithInflation,
            accumulatedDepositsRealCF: accumulatedDepositsRealCF,
          },
        };

        if (i < this.invProj.raw.duration) {
          this.totalAdditionalContributionN += additionalContribution;
          this.totalReturnOrInterestNominal += dividend;
        }

        this.totalAdditionalContribution += additionalContribution;
        // this.totalReturnOrInterestNominal += dividend;
        this.totalReturnOrInterestWithInflation += dividendWithInflation;
        this.plan.push(this.yearlyPlanSchedule);
      }

      this.totalInvestmentNominal =
        this.invProj.raw.startingDeposit +
        this.totalAdditionalContributionN +
        this.totalReturnOrInterestNominal;
      this.totalInvestmentWithInflation =
        this.invProj.raw.startingDeposit +
        this.totalAdditionalContribution +
        this.totalReturnOrInterestWithInflation;

      // this.invProj.raw.durationI = getYearsAndMonths()
      // result

      this.lastYearPlan = {
        nominal: {
          ofTerm: this.getYearsAndMonths(result),
          accumulatedDepositsBF: this.totalInvestmentNominal,
          additionalContribution: this.calculateLastYearContribution(result),
          dividend: this.calculateLastYearDividen(
            this.totalInvestmentNominal,
            result
          ),
          accumulatedDepositsCF: this.invProj.raw.yourTarget,
        },
        withInflation: {
          ofTerm: this.getYearsAndMonths(resultI),
          accumulatedDepositsRealBF: this.totalInvestmentWithInflation,
          additionalContribution: this.calculateLastYearContribution(resultI),
          dividendWithInflation: this.calculateLastYearDividen(
            this.totalInvestmentWithInflation,
            resultI
          ),
          accumulatedDepositsRealCF: this.invProj.raw.yourTarget,
        },
      };

      this.totalReturnOrInterestNominal += this.calculateLastYearDividen(
        this.totalInvestmentNominal,
        result
      );

      this.totalReturnOrInterestWithInflation += this.calculateLastYearDividen(
        this.totalInvestmentWithInflation,
        resultI
      );

      this.totalAdditionalContributionN += this.calculateLastYearContribution(
        result
      );

      this.totalAdditionalContribution += this.calculateLastYearContribution(
        resultI
      );

      this.invProjCalculated = true;
    },
    calculateLastYearContribution(t) {
      let total = Math.floor(t);
      let decimalPart = t - total;
      return this.invProj.raw.additionalContribution * decimalPart;
    },

    calculateLastYearDividen(tI, r) {
      return (
        this.invProj.raw.yourTarget - tI - this.calculateLastYearContribution(r)
      );
    },
    getYearsAndMonths(n) {
      let message = "";
      if (this.invProj.raw.period == 1) {
        let totalYears = n;
        let years = Math.floor(totalYears);
        let decimalPart = totalYears - years;
        let months = Math.round(decimalPart * 12);
        if (months === 12) {
          years += 1;
          months = 0;
        }

        message = `${years} years and ${months} months`;
      } else if (this.invProj.raw.period == 12) {
        let totalMonths = n;

        // Step 1: Extract integer part (full months)
        let fullMonths = Math.floor(totalMonths);

        // Step 2: Convert decimal part into weeks
        let decimalMonths = totalMonths - fullMonths;
        let weeksPerMonth = 4.34524;
        let weeks = decimalMonths * weeksPerMonth;

        // If you want to round the number of weeks to the nearest whole number
        let roundedWeeks = Math.round(weeks);

        message = `${fullMonths} months and ${roundedWeeks} weeks`;
      } else if (this.invProj.raw.period == 52.14) {
        let weeks = n;

        // Extracting the whole number of weeks
        let wholeWeeks = Math.floor(weeks); // Returns 28

        // Extracting the decimal part of weeks and converting it to days
        let fractionalWeeks = weeks - wholeWeeks; // Returns 0.613398165781
        let days = Math.round(fractionalWeeks * 7); // Multiply by 7 to convert weeks to days, then round to nearest whole day

        message = wholeWeeks + " weeks and " + days + " days";
      } else {
        message = n + " days ";
      }
      return message;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    onSubmit(values) {
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
